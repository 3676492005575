import $x from "@/libs/$x";
import routeStore from "./routes";

export default {
  namespaced: true,
  state: {
    shopId: null,
    shopCode: null,
    merchantId: null,
    mrchConfigId: null,
    enableCustomerDataShare: false,
    totalAccountBalance: 0,
    totalRechargeCardBalance: 0,
    userInfo: {},
    merchantShops: [],
    merchantUserInfo: {},
    shops: [],
    shopCommonSetting: {},
    loginUserType: null,
    userPermission:null,
    isManager: false, // 是否商户后台管理员 loginUserType：0/4
  },
  mutations: {
    setUserInfo: (state, data) => {
      const isManager = JSON.parse(localStorage.getItem("IS_MANAGER"));
      if (isManager) {
        state.userInfo = data.userinfo;
        state.shops = [{ id: "0", shopCode: "ONLY BEAUTY" }];
      } else {
        state.userInfo = data.userinfo;
        state.shops = data.shops;
        state.merchantId = data.merchantConfig.merchantId;
        state.mrchConfigId = data.merchantConfig.id;
        state.totalAccountBalance = data.merchantConfig.totalAccountBalance;
        state.totalRechargeCardBalance =
          data.merchantConfig.totalRechargeCardBalance;
        state.enableCustomerDataShare =
          !!data.merchantConfig.enableCustomerDataShare;
        state.merchantShops = data.merchantShops;
        state.merchantUserInfo = data.merchantUserInfo || {};
      }
      state.userPermission = data.userPermission;
    },
    setCommonSetting: (state, data) => {
      state.shopCommonSetting = data;
    },
    setLoginUsetType: (state, data) => {
      state.loginUserType = data;
      state.isManager = data == 0 || data == 4 ? true : false;
      localStorage.setItem("IS_MANAGER", state.isManager);
    },
  },
  getters: {
    role: (state) => {
      if (state.userInfo) {
        return state.userInfo.position;
      }
      return "";
    },
    currentShop: (state) => {
      let current = {};
      if (state.shopId) {
        state.shops.forEach((item) => {
          if (item.id === state.shopId) {
            current = item;
          }
        });
      }
      return current;
    },
  },
  actions: {
    changeCustomerDataShare({ state, commit, dispatch, getters }, val) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data, code, message } = await $x.put(
            `/platform/${state.merchantId}/merchant/merchantConfig`,
            {
              id: state.mrchConfigId,
              merchantId: state.merchantId,
              enableCustomerDataShare: val ? 1 : 0,
            }
          );
          if (code === 200) {
            resolve(message);
          } else {
            reject(message);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    updateUserInfo({ state, commit, dispatch, getters }) {
      return new Promise(async (resolve, reject) => {
        const userInfo = $x.localStorage.getItem("USER_INFO");
        if (!userInfo) {
          resolve();
          return;
        }
        const isManager = JSON.parse(localStorage.getItem("IS_MANAGER"));
        let url = `/public/tokenuserinfo`;
        // 0:超级管理员/商户平台默认账号 1:注册商户 2:门店员工 3:推广员 4:商户平台用户
        // 其中0/4可以登录商户平台，1/2/3可以登录门店管理后台
        if (isManager) {
          url = `/public/pfmTokenUserInfo`;
        }
        const { data, code, message } = await $x.post(url, {
          params: {
            refreshToken: userInfo.refreshToken,
          },
        });

        if (code === 200) {
          if (data) {
            let userPermission = (!data.userPermission || data.userPermission.length === 0) ? [] : data.userPermission;
            if (data.userinfo.userType === 3) {
              const promoterConsole = {
                dispName: "推广员控制台",
                icon: "",
                id: 1012002,
                menuUrl: "",
                name: "推广员控制台",
                pid: 1002
              };
              const hasPromoterConsole = userPermission.some(item => item.id === promoterConsole.id);
              if (!hasPromoterConsole) {
                userPermission.push(promoterConsole);
              }

              const promoterConsoleBase = {
                dispName: "员工",
                icon: "",
                id: 1002,
                menuUrl: "",
                name: "员工",
                pid: 0
              };
              const hasPromoterConsoleBase = userPermission.some(item => item.id === promoterConsoleBase.id);
              if (!hasPromoterConsoleBase) {
                userPermission.push(promoterConsoleBase);
              }
            }
            data.userPermission = userPermission.length === 0 ? null : userPermission;

            console.log(data.userPermission)

            commit("setUserInfo", data);
            let shopCommonSetting = {};
            if (state.shopId && state.shops) {
              const currentShop = state.shops.find(
                (item) => item.id === state.shopId
              );
              if (currentShop) {
                shopCommonSetting = currentShop.shopCommonSetting;
                commit("setCommonSetting", shopCommonSetting);
              }
            }
            await dispatch(
              "routes/initRouter",
              {
                shopCommonSetting,
                roleData: data.userPermission,
                isManager,
              },
              {
                root: true,
              }
            ).catch((err) => {
              console.log(err);
            });
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    //刷新token
    refreshToken({ commit, dispatch, getters }) {
      return new Promise(async (resolve, reject) => {
        const userInfo = $x.localStorage.getItem("USER_INFO");
        if (userInfo) {
          const reTime = userInfo["expireTime"];
          if (new Date().getTime() > reTime) {
            $x.localStorage.removeItem("USER_INFO");
            resolve();
            return;
          }
          if (new Date().getTime() + 3600000 > reTime) {
            const refreshToken = userInfo["refreshToken"];
            const response = await $x.post("/public/refreshtoken", {
              params: {
                refreshToken,
              },
            });
            if (response && response["accessToken"]) {
              const saveInfo = response;
              saveInfo["expireTime"] =
                new Date().getTime() + saveInfo["expiryDuration"];
              $x.localStorage.setItem("USER_INFO", saveInfo);
              resolve();
              return;
            }
            resolve();
          }
        }
      });
    },
    //登录
    handleLogin(
      { state, commit, dispatch, getters },
      { userAccount, password }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await $x.post("/public/signin", {
            params: {
              userAccount,
              password,
            },
          });
          // const response = await $x.post("/public/console/login", {
          //   params: {
          //     userAccount,
          //     password,
          //   },
          // });
          if (response.accessToken) {
            const userInfo = response;
            userInfo["expireTime"] =
              new Date().getTime() + userInfo["expiryDuration"];
            $x.localStorage.setItem("USER_INFO", userInfo);
            commit("setLoginUsetType", userInfo.userType);
            // 商户平台跳转至权限菜单第一个
            // 门店管理后台跳转至 /shopList
            if (userInfo.userType == 0 || userInfo.userType == 4) {
              await dispatch("routes/toShopListRouter", null, {
                root: true,
              });
            } else {
              await dispatch("routes/toShopListRouter", null, {
                root: true,
              });
            }

            resolve();
          } else {
            reject(response.message);
          }
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    //商户管理后台登录
    handleShopManageLogin(
      { state, commit, dispatch, getters },
      { userAccount, password }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data: response } = await $x.post("/public/console/login", {
            params: {
              userAccount,
              password,
            },
          });
          if (response.accessToken) {
            const userInfo = response;
            userInfo["expireTime"] =
              new Date().getTime() + userInfo["expiryDuration"];
            $x.localStorage.setItem("USER_INFO", userInfo);
            commit("setLoginUsetType", userInfo.userType);
            // 商户平台跳转至权限菜单第一个
            // 门店管理后台跳转至 /shopList
            if (userInfo.userType == 0 || userInfo.userType == 4) {
              await dispatch("routes/toShopListRouter", null, {
                root: true,
              });
            } else {
              await dispatch("routes/toShopListRouter", null, {
                root: true,
              });
            }

            resolve();
          } else {
            reject(response.message);
          }
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    handleStaffLogin(
      { state, commit, dispatch, getters },
      { shopId, userAccount, password }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await $x.post(
            `/public/${shopId}/commonUser/signin`,
            {
              params: {
                userAccount,
                password,
              },
            }
          );
          if (response.accessToken) {
            const userInfo = response;
            userInfo["expireTime"] =
              new Date().getTime() + userInfo["expiryDuration"];
            $x.localStorage.setItem("USER_INFO", userInfo);
            commit("setLoginUsetType", userInfo.userType);
            // 商户平台跳转至权限菜单第一个
            // 门店管理后台跳转至 /shopList
            if (userInfo.userType == 0 || userInfo.userType == 4) {
              await dispatch("routes/toShopListRouter", null, {
                root: true,
              });
            } else {
              await dispatch("routes/toShopListRouter", null, {
                root: true,
              });
            }

            resolve();
          } else {
            reject(response.message);
          }
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    // handleCommonSetting({
    //   commit,
    //   dispatch,
    //   getters
    // }, {
    //   name,
    //   password,
    //   rePassword,
    //   userAccount
    // }) {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       const {
    //         success,
    //         message
    //       } = await $x.post('/public/signup', {
    //         params: {
    //           name,
    //           password,
    //           userAccount
    //         }
    //       })
    //       console.log(success, message)
    //       if (success === true) {
    //         resolve()
    //       } else {
    //         reject(message)
    //       }
    //     } catch (err) {
    //       if (err.response.status === 400) {
    //         reject(err.response.data.message)
    //       } else if (!err.response.data.success) {
    //         reject(err.response.data.message)
    //       }
    //     }
    //   });
    // },
    //注册
    handleRegister({ commit, dispatch, getters }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const { code, message } = await $x.post("/public/signup", {
            params,
          });
          if (code === 200) {
            resolve();
          } else {
            reject(message);
          }
        } catch (err) {
          if (err.response.status === 400) {
            reject(err.response.data.message);
          } else if (!err.response.data.success) {
            reject(err.response.data.message);
          }
        }
      });
    },
  },
};

import Vue from "vue";
import Router from "vue-router";
import $x from "../libs/$x";

import shopRouter from "./modules/shop";
import shopManageRouter from "./modules/shopManage";
import previewRouter from "./modules/preview";
import { i18n, $t, lang } from "@/pages/i18n";

//同步加载，合并打包
Vue.use(Router);

const vueRouter = Router.prototype.push;

//同步加载，合并打包
Router.prototype.push = function push(location) {
  return vueRouter.call(this, location).catch((err) => err);
};

// 组件懒加载：组件会被webpack打包多个js，当路由被访问的时候只加载相应组件js
const Test = (resolve) => require(["../views/test.vue"], resolve);
const Layer = (resolve) => require(["../views/layer/layer.js"], resolve);
const Login = (resolve) => require(["../views/loginview/login.js"], resolve);
const shopManageLogin = (resolve) =>
  require(["../views/loginview/shopManageLogin.js"], resolve);
const StaffLogin = (resolve) =>
  require(["@/views/loginview/staffLogin.js"], resolve);

const Register = (resolve) =>
  require(["../views/register/register.js"], resolve);

export const commonRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/console/login",
    name: "shopManageLogin",
    component: shopManageLogin,
  },
  {
    path: "/registshop",
    name: "register",
    component: Register,
  },
  {
    path: "/",
    component: Layer,
  },
  {
    path: "/test",
    component: Test,
  },
].concat(previewRouter);

export const shopRoutes = shopRouter;
export const shopManageRoutes = shopManageRouter;

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: commonRoutes,
  });

const router = createRouter();

const whiteList = ["/login", "/console/login", "/h5/", "/registshop"];

const checkInRoute = (route, path) => {
  if (route.path === path) {
    return true;
  }
  if (route.children) {
    for (let i = 0; i < route.children.length; i++) {
      if (checkInRoute(route.children[i], path)) {
        return true;
      }
    }
  }
  return false;
};

router.onError((error) => {
  const jsPattern = /Loading chunk (\S)+ failed/g;
  const cssPattern = /Loading CSS chunk (\S)+ failed/g;
  const isChunkLoadFailed = error.message.match(jsPattern || cssPattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    // localStorage.setItem('targetPath', targetPath)
    window.location.reload();
  }
});

router.beforeEach(async (to, from, next) => {
  // console.log(to, from)
  // iView.LoadingBar.start();

  const safeAim = whiteList.some((inner) => {
    if (to.path.startsWith(inner)) {
      return true;
    }
  });
  if (safeAim) {
    next();
    return;
  }

  let userInfo = $x.localStorage.getItem("USER_INFO");
  if (!userInfo) {
    next({
      name: "login",
    });
    return;
  }

  //仅用于初步开发阶段调试
  let storeRouter = router.app.$options.store.state.routes.routes;
  if (storeRouter.length == 0) {
    let nextPath = null;
    await router.app.$options.store.dispatch("user/updateUserInfo");
    if (to.path === "/") {
      if (router.app.$options.store.state.routes.addRoutes.length > 0) {
        router.app.$options.store.state.routes.addRoutes.some((item) => {
          if (item.meta.show) {
            nextPath = item.path;
            return true;
          }
          return false;
        });
      }
    }

    if (!to.path.startsWith("/shopList")) {
      let shopId = $x.localStorage.getItem("SHOP_ID");
      if (!shopId) {
        router.app.$options.store.state.user.shopId = null;
        router.app.$options.store.state.user.shopCode = null;
        // router.app.$options.store.state.user.merchantId = null
        next({
          name: "shopList",
        });
        return;
      }
    }

    if (router.app.$options.store.state.routes.addRoutes) {
      if (nextPath) {
        next({
          path: nextPath,
          replace: true,
        });
      } else {
        let hasRouter = router.app.$options.store.state.routes.addRoutes.some(
          (item) => {
            if (checkInRoute(item, to.path)) {
              return true;
            }
            return false;
          }
        );
        if (hasRouter) {
          next({
            path: to.path,
            query: to.query,
            params: to.params,
          });
        } else {
          next({
            name: "login",
          });
        }
      }
    } else {
      // next({
      //   name: 'login'
      // });
    }
  }

  next();
});

router.afterEach((to) => {
  // iView.LoadingBar.finish();
  window.scrollTo(0, 0);
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
